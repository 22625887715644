import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"
import { FormattedMessage, useIntl } from "react-intl"

function Clientes({clientes}){

    const intl = useIntl();

    return(
        <section className="partners-v8-sec v2">
			<div className="container">
				<div className="title-v8 text-center">
                    <h2 
                        className="black-clr" 
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'customersTitle', defaultMessage:'Han <span class="clr-yeloow">confiado</span> en nosotros'})}}
                    />
					<p><FormattedMessage id="customersSubtitle" defaultMessage="Hemos trabajado con centenares de clientes, entre ellos puedes encontrar los siguientes:"/></p>
				</div>
				<div className="pt-logos-v8 row">
                    {clientes.map(cliente=>(
                        <div className="col-lg-2" key={cliente.id}>
                            <div className="pt-logo-v8">
                                <GatsbyImage
                                    alt={cliente.title}
                                    image={cliente.logo.gatsbyImageData}
                                    width={220}
                                    loading="eager"
                                    backgroundColor="#fff"
                                    title={cliente.title}
                                />
                            </div>
                        </div>
                    ))}
				</div>
			</div>
            <style>{`
                .title-v9{
                    margin-top:90px;
                }
            `}</style>
		</section>
    )

}

export default Clientes